import {
    ClientSDK,
    FlzEvent,
    OpenItemViewerPayload,
    PingPayload,
    SourceType,
    getAnalyticsItemId,
    getAnalyticsSourceType
} from "client-sdk";
import {Store} from "../services/Store";
import {AbstractController} from "./AbstractController";
import GUID from "../helpers/GuidHelper";
import PingsService from "../services/PingsService";
import SessionService from "../services/SessionsService";
import {LiveBoardController} from "./LiveBoardController";
import CampaignAnalytics from "../services/CampaignAnalytics";
import get from "lodash/get";
import {onErrorIfExists, onSuccessIfExists} from "../helpers/Helpers";
import VisibilityService from "../services/VisibilityService";

const TIME_SPENT_KEY = GUID.keys.timeSpent;
const TOKEN_PARAM = "token";
const EVENT_DURATIONS = [5, 10, 30, 60, 120, 300, 600];
const FIRST_DURATION_INDEX = 0;
const SECONDS_FACTOR = 1000;

export class AnalyticsController extends AbstractController {

    private boardId: number;
    private sdk: ClientSDK;
    private store: Store;
    private pingsService: PingsService;
    private itemViewGuid: string | null;
    private boardController: LiveBoardController;
    private campaignAnalyticsService: CampaignAnalytics;
    private durationTimeout: NodeJS.Timeout;
    private nextDurationIndex: number;
    private visibilityService: VisibilityService;
    private sessionService: SessionService;

    constructor(boardController: LiveBoardController, sdk: ClientSDK, store: Store, visibilityService: VisibilityService) {
        super();

        this.boardController = boardController;
        this.sdk = sdk;
        this.store = store;
        this.boardId = this.store.state.board.id;
        this.pingsService = new PingsService(this, this.boardId);
        this.visibilityService = visibilityService;
        this.sessionService = new SessionService(this, this.visibilityService);

        const isPreview = sdk.fetcher.options.isPreview || false;
        this.campaignAnalyticsService = new CampaignAnalytics(isPreview);
        this.initialize();
    }

    async initialize() {
        this.sdk.analytics.validateSession().then(() => {
            this.handleNewSession(true);

            const searchParams = new URLSearchParams(location.search);
            if (searchParams.has(TOKEN_PARAM)) {
                this.sdk.analytics.updateInvitationUsed(searchParams.get(TOKEN_PARAM) || "");
            }
        });

        const integrations = this.store.state.board.integrations;
        const campaignAnalyticsSettings =  {
            ga: get(integrations, 'ga', {}),
            munchkin: get(integrations, ['marketo', 'munchkin'], {}),
            eloqua: get(integrations, 'eloqua', {}),
            pardot: get(integrations, 'pardot', {})
        };
        this.campaignAnalyticsService.register(campaignAnalyticsSettings);
    }

    private handleNewSession(isFirstSession: boolean = false) {
        this.setSessionState(true);
        this.trackBoardView();

        const initialItem = this.getItem();
        // track again item view if it's a renewing session
        if (initialItem && !isFirstSession) {
            this.itemChanged(initialItem);
        }
    }


    // ctaSubmitHandler(e: FlzEvent) {
    //    TODO - move to here?
    // }


    // likeItem(e: FlzEvent) {
    //      //TODO - move to here instead of liveboard controller and go through analytics?
    // }

    itemChanged(item: OpenItemViewerPayload) {
        this.itemViewGuid = GUID.create(TIME_SPENT_KEY); // create a new guid for timespent tracking
        this.trackItemView(
            item.content_item_id,
            getAnalyticsSourceType(item),
            getAnalyticsItemId(item),
            this.itemViewGuid
        );
        this.trackItemViewDuration(item.content_item_id);
        this.pingsService.onItemChanged(item.id, item.content_item_id, this.itemViewGuid);
        this.handleUrlChanged();
    }

    trackItemView(contentItemId: number, sourceType: SourceType, itemId?: number, guid?: string) {
        // @ts-ignore
        this.sdk.analytics.trackLeadContentView(contentItemId, sourceType, guid, itemId);
    }

    categoryChanged() {
        this.handleUrlChanged();
    }

    itemViewerClosed() {
        this.itemViewGuid = null;
        this.pingsService.onItemChanged(null, null, this.itemViewGuid);
        this.handleUrlChanged();
    }

    trackLeadEvent(e: FlzEvent) {
        const { action } = e.payload.trackedLeadEvent;
        const { sourceType, contentItemId, itemId } = e.payload.trackedLeadEvent.payload;
        let promise: Promise<unknown>;
        let recommendationEventName: string = '';

        switch (action) {
            case "lead-link-click": {
                promise = this.sdk.liveboard.trackLinkClick(this.boardId, e.payload.trackedLeadEvent.payload);
                break;
            }
            case "track-download-file": {
                recommendationEventName = 'item_download';
                promise = this.sdk.analytics.trackDownloadFileV2(
                    sourceType,
                    contentItemId,
                    itemId
                )
                    .then(() => onSuccessIfExists(e))
                    .catch(err => onErrorIfExists(e, err));
                break;
            }
            case "track-lead-like-content": {
                recommendationEventName = 'item_like';
                promise = this.sdk.analytics.trackLeadLikeContentV2(
                    sourceType,
                    contentItemId,
                    itemId
                )
                    .then(() => onSuccessIfExists(e))
                    .catch(err => onErrorIfExists(e, err));
            }
        }

        // TODO - can move to server side
        this.handleRecommendationEvents(contentItemId, recommendationEventName);

        // @ts-ignore - wtf?
        promise && (promise.then(e.onSuccess).catch(e.onError));
    }

    trackBoardView() {
        this.sdk.analytics.trackLeadBoardView(this.boardId);
    }

    sendPing(payload: PingPayload) {
        this.sdk.analytics.sendPing(payload);
    }

    getItem(): OpenItemViewerPayload {
        return this.store.state.item_viewer.current;
    }

    getLeadId(): number {
        return this.store.state.lead.id;
    }

    getSessionGuid(): string {
        return this.store.state.session_guid;
    }

    setSessionState(isActive: boolean) {
        !isActive && this.clearDurationTracking();
        this.pingsService.onSessionStateChanged(isActive);
    }

    createSession() {
        this.sdk.analytics.createSession().then((res) => {
            console.debug("created new session", res.data.guid);
            this.store.setState({session_guid: res.data.guid});

            this.handleNewSession();
        });
    }

    setTabIsHidden(isHidden: boolean) {
        this.store.setState({isTabHidden: isHidden});
    }

    handleUrlChanged() {
        this.campaignAnalyticsService.track(window.location.href);
        const item = this.store.state.item_viewer.current;

        // TODO - should probably sit in the BoardEventsHandlers but requires a bit more work
        const event = new CustomEvent("Folloze.pageview", {
            bubbles: true,
            cancelable: true,
            detail: {
                location: window.location.href,
                lead: this.store.state.lead,
                // @ts-ignore
                itemId: item?.id,
                // @ts-ignore
                itemName: item?.name
            }
        });

        document.dispatchEvent(event);
    }

    trackVideoSectionDuration(e: FlzEvent): void {
        const { payload: { content_item_id: contentItemId }, type } = e.payload;
        this.clearDurationTracking();

        if (type === 'video_started' && contentItemId) {
            this.startDurationTracking(contentItemId);
        }
    }

    // TODO - once we update the analytics, can move to pingy
    private trackItemViewDuration(contentItemId: number): void {
        this.clearDurationTracking();

        if (contentItemId) {
            this.startDurationTracking(contentItemId);
        }
    }

    private clearDurationTracking(): void {
        clearTimeout(this.durationTimeout);
        this.nextDurationIndex = FIRST_DURATION_INDEX;
    }

    private startDurationTracking(contentItemId: number): void {
        this.durationTimeout = setTimeout(() => {this.handlePublishDuration(contentItemId);}, this.getNextDuration());
    }

    private handlePublishDuration(contentItemId: number): void {
        const duration = this.getDuration();
        this.handleRecommendationEvents(contentItemId, `item_view_${duration}_sec`);

        const shouldTrackNextDuration = this.nextDurationIndex < (EVENT_DURATIONS.length - 1);

        if (shouldTrackNextDuration) {
            this.nextDurationIndex += 1;
            this.startDurationTracking(contentItemId);
        } else {
            this.clearDurationTracking();
        }
    }
    
    private getNextDuration(): number {
        const isFirstDurationEvent = this.nextDurationIndex == FIRST_DURATION_INDEX;
        const duration = isFirstDurationEvent ?
          this.getDuration() :
          this.getDuration() - this.getDuration(this.nextDurationIndex - 1);

        return duration * SECONDS_FACTOR;
    }

    private getDuration(index = this.nextDurationIndex): number {
        // @ts-ignore
        return EVENT_DURATIONS[index];
    }

    handleRecommendationEvents(contentItemId: number, event: string): void {
        const isRecommendationsAiEnabled = window["FollozeState"]?.initialState?.features?.recommendationsAi || false;
        if (!isRecommendationsAiEnabled) {
            return;
        }
        this.sdk.analytics.publishLeadEvents(contentItemId, Date.now(), event);
    }

    trackLiveEventAttendance(e: FlzEvent): void {
        let trackType: "create"|"update";
        let guid: string;

        if(e.payload.guid){
            trackType = "update";
            guid = e.payload.guid;
        }
        else {
            trackType = "create";
            guid = GUID.createRandomGuid();
        }

        const attendanceData = {
            boardId: this.boardId,
            guid: guid,
            widgetId: e.payload.widgetId,
            activityType: e.payload.activityType,
            eventName: e.payload.eventName,
        };

        this.sdk.analytics.liveEvent.trackAttendance(trackType, attendanceData)
            .then(() => onSuccessIfExists(e, guid))
            .catch(err => onErrorIfExists(e, err));
    }

    getLastIdleCheck(): any {
        return this.sessionService?.getLastIdleCheck();
    }
}
