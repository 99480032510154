/**
 * This service will detect if the user is idle by following these rules:
 * 1) Once a minute the service will check if the user moved his mouse
 * 2) If he did nothing happens
 * 3) If the user doesn't move his mouse for 2 minutes, idle is set to true
 * 4) The only way to escape idle mode is to click somewhere on the page
 */

const TIME_FOR_IDLE = 1000 * 60 * 10; // 10 minutes
const TIME_FOR_DETECTION = 1000 * 30; // 30 seconds

export type MouseAction = {
    clientX: number;
    clientY: number;
    pageX: number;
    pageY: number;
    movementX: number;
    movementY: number;
    date: Date;
};

export default class IdleService {
    private isIdle: boolean;
    private runningTimout: NodeJS.Timeout;
    private changeHandler: Function;
    public lastMouseMove: MouseAction;

    constructor() {
        this.isIdle = false;
        this.detectIsIdle = this.detectIsIdle.bind(this);
        this.setIdle = this.setIdle.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.setNotIdle = this.setNotIdle.bind(this);
        this.countForIdleDetect();
        window.addEventListener("beforeunload", () => this.destroy());
    }

    destroy() {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.changeHandler = () => {};
        this.removeEventListeners();
    }

    removeEventListeners() {
        document.body.removeEventListener("mousemove", this.onMouseMove);
        document.body.addEventListener("mousemove", this.setNotIdle);
    }

    countForIdleDetect() {
        setTimeout(this.detectIsIdle, TIME_FOR_DETECTION);
    }

    detectIsIdle() {
        this.runningTimout = setTimeout(this.setIdle, TIME_FOR_IDLE);
        document.body.addEventListener("mousemove", this.onMouseMove);
    }

    onMouseMove(e: MouseEvent) {
        this.lastMouseMove = {
            clientX: e.clientX,
            clientY: e.clientY,
            pageX: e.pageX,
            pageY: e.pageY,
            movementX: e.movementX,
            movementY: e.movementY,
            date: new Date(),
        };
        
        document.body.removeEventListener("mousemove", this.onMouseMove);
        clearTimeout(this.runningTimout);
        this.countForIdleDetect();
    }

    setIdle() {
        this.removeEventListeners();

        this.isIdle = true;
        this.fireChange();
    }

    setNotIdle() {
        document.body.removeEventListener("mousemove", this.setNotIdle);
        this.countForIdleDetect();

        this.isIdle = false;
        this.fireChange();
    }

    fireChange() {
        this.changeHandler && this.changeHandler(this.isIdle);
    }

    onChange(handler: Function) {
        this.changeHandler = handler;
    }
}
