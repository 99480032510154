import {
    BoardSellerResponseV1,
    ClientSDK,
    FetcherOptions,
    FlzEvent,
    FlzBoardEvent,
    GeoLocationResponseV1,
    ContentWidgetConfig,
    CategoryResponseV2,
    WidgetConfig,
    CookieConsentWidget,
    appendParamsToUrl,
    removeQueryParams,
    getAnalyticsItemId,
    getAnalyticsSourceType,
    getBoardPublicLink,
    type SectionConfig,
    type Lead,
    type OpenItemViewerPayload,
    type JourneyItem,
    emit,
} from "client-sdk";
import {State, Store} from "../services/Store";
import {AbstractController} from "./AbstractController";
import {LiveBoard} from "../components/LiveBoard";
import HeaderLoadListener from "../services/HeaderLoadListener";
import {RouteParams, Router} from "../Router";
import {
    LINK_TARGETS,
    paramsToQueryString,
    queryToParamsObject,
    removeBoardSlugFromURL,
    withScheme
} from "../helpers/UrlHelper";
import {AnalyticsController} from "./AnalyticsController";
import CookiesConsentService from "../services/CookiesConsentService";
import enrichData from "../services/dataEnrichment/DataEnrichmentService";
import cookieMatching from "../services/cookieMatching/CookieMatchingService";
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import AnalyticsService from "../services/AnalyticsService";
import VisibilityService from "../services/VisibilityService";
import {onErrorIfExists, onSuccessIfExists} from "../helpers/Helpers";
import minBy from "lodash/minBy";

const PERSONAL_DETAILS_PARAMS = ["em", "fn", "ln", "ro", "ph", "co", "ind", "loc", "inby", "dom", "safetoken"];

export class LiveBoardController extends AbstractController {
    private store: Store;
    private board: LiveBoard;
    private router: Router;
    private boardId: number;
    private sdk: ClientSDK;
    private envConfig: { fetcherOptions: FetcherOptions; };
    public analyticsController: AnalyticsController;
    public cookiesConsentService: CookiesConsentService;
    private boardLoaded: boolean = false;
    private headerLoadListener: HeaderLoadListener;
    public analyticsService: AnalyticsService;
    public visibilityService: VisibilityService;

    constructor(router: Router, board: LiveBoard, store: Store) {
        super();
        this.store = store;
        this.board = board;
        this.router = router;
        this.boardId = store.state.board.id;
        this.envConfig = window["FollozeState"].envConfig;
        this.headerLoadListener = new HeaderLoadListener();
        this.visibilityService = new VisibilityService();
        this.visibilityService.onChange((isHidden: boolean) => this.handleVisibilityChange(isHidden));
    }

    async initialize() {
        const sanitizedUrl = this.sanitizeUrlWithToken();
        if (sanitizedUrl) {
            this.router.navTo(sanitizedUrl, undefined, undefined, false);
        }

        if (window["FlzClientSDK"] == "pending") {
            await this.waitForSdk();
        } else if (window["FlzClientSDK"]) {
            this.sdk = window["FlzClientSDK"];
        } else {
            this.envConfig.fetcherOptions.organizationId = window["FollozeState"].initialState.board.organization_id || -1;
            this.sdk = await ClientSDK.create(this.envConfig.fetcherOptions);
            console.debug("SDK created");
        }

        this.analyticsController = new AnalyticsController(this, this.sdk, this.store, this.visibilityService);
        this.analyticsService = new AnalyticsService(this, this.sdk, this.store);

        this.cookiesConsentService = new CookiesConsentService(
            this.store.state.privacy_settings,
            this.store.state.board,
            // @ts-ignore - todo: add to client-sdk initial state type
            window["FollozeState"].initialState.is_regulated_country,
            this
        );

        if (isEmpty(window["FollozeState"].initialState.lead.account_domain)) {
            enrichData(this.sdk, window["FollozeState"].initialState.data_service_configuration);
        }

        this.cookiesConsentService.onConsentGiven(() => {
            const urlParamsEnrichment = this.cookiesConsentService.isHiddenLead();
            if(urlParamsEnrichment && this.cookiesConsentService.isExplicitConcentNeeded() && this.board.isPersonalized())
                this.board.refreshPerso();
            cookieMatching(this.sdk, window["FollozeState"].initialState.cookie_matching);
        });
    }

    private async waitForSdk() {
        return new Promise<void> (resolve => {
            const interval = setInterval(() => {
                if(window["FlzClientSDK"] != "pending") {
                    clearInterval(interval);
                    this.sdk = window["FlzClientSDK"];
                    resolve();
                }
            }, 0);
        });
    }

    private sanitizeUrlWithToken() {
        const { pathname, search } = location;
        
        const token = window["FollozeState"].initialState.token;
        const searchObject = queryToParamsObject(search);

        if (!isEmpty(pick(searchObject, PERSONAL_DETAILS_PARAMS))) {
            const params: Record<string, string> = omit(searchObject, [...PERSONAL_DETAILS_PARAMS, "token"]);
            if(token) {
                params.token = token;
            }
            const queryString = paramsToQueryString(params);

            return removeBoardSlugFromURL(`${pathname}${queryString}${location.hash}`);
        }
    }

    notifyWidgets(e: FlzEvent) {
        this.board.widgetElements.map(w => w?.incomingEvents && w.incomingEvents(e));
        this.board.getAllRibbonElements().map(w => w?.incomingEvents && w.incomingEvents(e));
        this.board.floatingWidgetElements.map(w => w?.incomingEvents && w.incomingEvents(e));
        this.board.itemViewer?.incomingEvents(e);
    }

    setBoardLoaded(isLoaded: boolean) {
        this.boardLoaded = isLoaded;
    }

    autoScrollAfterLanding() {
        // if there is a hash in the url, scroll to it
        let hash = this.router.getRouterState()[0]?.hashString;
        if (hash) {
            // decode url
            hash = decodeURIComponent(hash);
            console.debug("scroll to hash", hash);
            this.autoScrollToHash(hash);
            return;
        }

        // nav to section by opened item??
        const state: State = this.store.state;
        // @ts-ignore
        const currentItem = state.item_viewer?.current as OpenItemViewerPayload;
        // @ts-ignore - get the first widget that has the current item id or has all categories in source
        const widget = this.getJourneyWidget(currentItem);
        if (widget && currentItem?.viewer_settings?.design === "lightbox") {
            if (widget.sectionId) {
                const section = this.board.sections.find(s => s.id === widget.sectionId);
                if (section) {
                    console.debug("scroll to section by item");
                    this.scrollToSection(section);
                }
            }
            return;
        }

        // if there is a category slug in the url and no item, scroll to the first widget that has all items
        const categorySlug = this.router.getRouterState()[0]?.data?.categorySlug;
        if (!currentItem && categorySlug && widget) {
            const section = this.board.sections.find(s => s.id === widget.sectionId);
            if (section) {
                console.debug("scroll to section by category");
                this.scrollToSection(section);
            }
        }
    }
    
    autoScrollToHash(hash: string) {
        const sections = this.board.sections;
        const anchorSection = sections.find(s => s["anchor"] === hash);
        if (anchorSection) {
            console.debug("scroll to section by anchor");
            this.autoScrollToAnchor(hash);
            return;
        }
        
        const widgetByHash = this.board.widgets.find(w => w["id"] === hash);
        if(widgetByHash) {
            console.debug("scroll to section by widget id");
            this.scrollToSectionByWidget(widgetByHash);
        }
    }

    autoScrollToAnchor(hash: string) {
        // now that the board is loaded and widgets already occupy space in the dom
        // we can navigate to the anchor if it exists
        const sections = this.board.sections;
        const selectedSection = sections.find(s => s["anchor"] === hash);
        if (selectedSection) {
            this.scrollToSection(selectedSection);
        }
    }

    scrollToSection(section: SectionConfig) {
        const widgetConfigs = this.board.widgets.filter((x) => x.sectionId === section.id);
        // find the widget with the lowest rowStart
        const highestWidget = minBy(widgetConfigs, "position.rowStart");
        if (!highestWidget) {
            return;
        }
        document.body.style.scrollBehavior = "smooth";
        const widget = this.board.getWidgetEl(highestWidget.id);
        widget?.scrollIntoView({behavior: "smooth", block: "start"});
    }

    scrollToSectionByWidget(widget: WidgetConfig) {
        const widgetSectionId = widget.sectionId;
        const widgetSection = this.board.sections.find(s => s.id === widgetSectionId);

        if(widgetSection){
            this.scrollToSection(widgetSection);
        }
    }

    stateChanged(state: any) {
        if (!this.boardLoaded) {
            return;
        }
        this.board.widgetElements.map(w => w?.stateChanged && w.stateChanged(state));
        this.board.floatingWidgetElements.map(w => w?.stateChanged && w.stateChanged(state));
        this.board.itemViewer?.stateChanged(state);
    }

    getLead(e: FlzEvent) {
        const lead = this.getLeadFromState();
        onSuccessIfExists(e, lead);
    }

    getLeadFromState(): Lead {
        return this.store.state.lead;
    }

    getCurrentCategorySlug(e: FlzEvent) {
        onSuccessIfExists(e, this.router.getCategorySlug());
    }

    getAllCategories(e: FlzEvent) {
        this.sdk.liveboard.getCategories(this.boardId)
            .then(categories => {
                this.store.setState({
                    categories: {
                        ...this.store.state.categories,
                        data: categories.data
                    }
                });
                onSuccessIfExists(e, categories);
            })
            .catch(err => onErrorIfExists(e, err));
    }

    getGeoLocationInfo(): Promise<GeoLocationResponseV1> {
        return this.sdk.liveboard.getGeoLocation();
    }

    getState() {
        return cloneDeep(this.store.state);
    }

    handleGetState(e: FlzEvent) {
        onSuccessIfExists(e, this.getState());
    }

    getCurrentItem(e: FlzEvent) {
        let currentItem: string | null = null;
        console.log('this.router.getItemSlug()', this.router.getItemSlug());
        
        if (this.router.getItemSlug()) { // TODO - use different method to decide if it's an item route
            currentItem = this.store.state.item_viewer?.current;
        }
        onSuccessIfExists(e, currentItem);
    }

    getInitialJourneyItem(e: FlzEvent) {
        // todo(itamar): the safe check here is a smell that hints that we run this when there is no item_viewer
        const journeyItem: JourneyItem = this.store.state.item_viewer?.current as JourneyItem;
        if (!journeyItem) {
            onSuccessIfExists(e, undefined);
            return;
        }

        const defaultData: ContentWidgetConfig = {
            item_viewer: {...window["FollozeState"].initialState.item_viewer.default},
            sources: {
                // -4 means = NONE_CATEGORIES_SELECTED_DUMMY_ID (in widgets)
                // this is to make none of the categories selected
                // empty array is treated as all categories in widgets
                flz_category_ids: [-4],
                sort: "order_on_board",
                ...(journeyItem.category_id ? {} : {virtual_category: {flz_item_ids: [journeyItem.id]}})
            }
        };

        console.log("get initial journey item", defaultData);

        const journeyWidget: WidgetConfig = this.getJourneyWidget(journeyItem)!;

        const journeyWidgetData: ContentWidgetConfig = journeyWidget?.data || defaultData;

        console.log("journeyWidgetData", journeyWidgetData);


        if(journeyWidget?.data?.cards?.visibility?.likes) {
            journeyWidgetData.item_viewer.allow_likes = journeyWidget.data.cards.visibility.likes;
        }

        // @ts-ignore
        e.onSuccess(journeyItem, journeyWidgetData.item_viewer, journeyWidgetData.sources);
    }

    getJourneyWidget(journeyItem?: JourneyItem): WidgetConfig | undefined {
        let journeyWidget: WidgetConfig | undefined;
        const params = this.router.getRouterState()[0]?.params;
        const journeyWidgetId = params?.fw || params?.w;
        if (journeyWidgetId) {
            journeyWidget = this.board.widgets.find(widget => widget.id.endsWith(journeyWidgetId));
        }

        // if (!journeyWidget) {
        //     journeyWidget = this.board.widgets.find(widget => {
        //         const widgetData: ContentWidgetConfig = widget.data;
        //         const isContentWidget = !!widgetData?.sources?.flz_category_ids;
        //         if (!isContentWidget) {
        //             return false;
        //         } else if (journeyItem?.category_id) {
        //             // -2 means = ALL_CATEGORIES_DUMMY_ID (in widgets), if it exists it means all categories are selected
        //             return (widgetData?.sources?.flz_category_ids?.length == 0
        //                 || widgetData?.sources?.flz_category_ids?.includes(-2)
        //                 || widgetData?.sources?.flz_category_ids?.includes(journeyItem?.category_id));
        //         } else {
        //             return widget.data?.sources?.all_items_categories?.visible;
        //         }
        //     });
        // }
        return journeyWidget;
    }

    getItem(e: FlzEvent) {
        this.sdk.liveboard.getItem(
            e.payload.id,
            this.boardId,
            e.payload.bySlug || false,
            e.payload.withPreviewMetadata || false,
        )
            .then(item => e.onSuccess!(item))
            .catch(err => e.onError!(err));
    }

    getItems(e: FlzEvent) {
        this.sdk.liveboard.getItems({
            boardId: this.boardId,
            ...e.payload
        })
            .then(items => e.onSuccess!(items))
            .catch(err => e.onError!(err));
    }

    getJourney(e: FlzEvent) {
        this.sdk.liveboard.getJourneyItems(e.payload.itemId,{
            boardId: this.boardId,
            ...e.payload
        })
            .then(journey => e.onSuccess && e.onSuccess(journey))
            .catch(err => e.onError && e.onError(err));
    }

    openItemViewer(e: FlzEvent) {
        this.board.itemViewer.headerLoadedPromise = this.headerLoadListener.headerLoadedPromise;
        this.headerLoadListener.start(this.board);

        if (!e.payload.is_landing && e.payload.open_in_new_tab) {
            this.openLinkByTargetType({ link: e.payload.link_url, item: e.payload.item });

        } else {
            this.changeItem(e);
            this.board.itemViewer.open(e.payload);
        }
        emit(this.board, "item-viewer-opened");
    }

    handleWidgetUpdated(e: FlzEvent) {
        this.headerLoadListener.checkIfHeaderLoaded(e.emitter);
        e.skipWidgetsNotify = true;
    }

    changeItem(e: FlzEvent) {
        const route = e.payload.route;

        this.store.setState({
            item_viewer: {
                ...this.store.state.item_viewer,
                current: e.payload,
                history: {
                    ...this.store.state.item_viewer?.history,
                    [route]: e.payload
                }
            }
        });
        this.router.navTo(route, e.emitter.id);
        this.analyticsController.itemChanged(e.payload);
    }

    getModalElement(e: FlzEvent) {
        if (this.store.state.modal?.element && e.onSuccess) {
            e.onSuccess(
                this.store.state.modal?.element,
                this.store.state.modal?.options
            );
        }
    }

    openModal(e: FlzEvent) {
        this.store.setState({ modal: e.payload });

        // @ts-ignore
        const modalEl = this.board.modal;

        modalEl?.showModal(e.payload.element, e.payload.options);
    }

    closeModal() {
        if (this.store.state.modal) {
            this.store.setState({ modal: null });
            // @ts-ignore
            this.board.modal.hideModal();
        }
    }

    ctaSubmitHandler(e: FlzEvent) {
        let ctaAction: Function | undefined;
        const ctaType = e.payload.type;

        switch(ctaType) {
            case "form":
            case "registration":
                // todo: extract the ctaData type from widgets/src/internal/actionable.ts to the client-sdk
                ctaAction = (boardId: number, ctaData: any) => this.sdk.liveboard.saveFormCta(boardId, ctaData);
                break;
            case "new_tab":
            case "inline":
                ctaAction = (boardId: number, ctaData: any) => this.sdk.liveboard.saveLinkCta(boardId, ctaData);
                break;
            case "share":
                ctaAction = (boardId: number, ctaData: any) => this.sdk.liveboard.saveShareCta(boardId, ctaData);
                break;
            case "contact":
                ctaAction = (boardId: number, ctaData: any) => this.sdk.liveboard.saveContactCta(boardId, ctaData);
                break;
            case "message":
                ctaAction = (boardId: number, ctaData: any) => this.sdk.liveboard.saveMessageCta(boardId, ctaData);
                break;
            default:
                ctaAction = undefined;
                break;
        }

        if (!ctaAction) {
            console.error(`cta type ${ctaType} is not supported`);
            return;
        }

        ctaAction && ctaAction(
            this.boardId,
            e.payload
        )
        .then((lead: Lead) => {
            this.store.setState({lead});
            if (ctaType === "registration" && this.store.state.board.landing_page == "registration") {
                this.addRegularPageLoader(87);
                setTimeout(() => {
                    // this.store.state.board.landing_page = "default";
                    // this.board.setPageByName(this.store.state.board.landing_page);
                    this.board.setPageByName("default");
                    this.addRegularPageLoader(100);
                    this.router.redoLanding();
                }, 2000);
            }
            return e.onSuccess && e.onSuccess(lead);
        })
        .catch((err: Error) => e.onError && e.onError(err));
    }

    shareHandler(e: FlzEvent) {
        this.sdk.liveboard.saveShareByEmailCta(
            this.boardId,
            e.payload.email,
            e.payload.email_template_id
        ).then(() => e.onSuccess && e.onSuccess());
    }

    getFormData(e: FlzEvent) {
        this.sdk.liveboard.getFormData(
            this.boardId,
            e.payload.formId,
            e.payload?.privacyMessageId
        )
            .then(formData => onSuccessIfExists(e, formData))
            .catch(err => onErrorIfExists(e, err));
    }

    closeItemViewer() {
        this.board.itemViewer.close();
        this.analyticsController.itemViewerClosed();
    }

    setCurrentItem(payload: OpenItemViewerPayload | null) {
        this.store.setState({
            item_viewer: {
                ...this.store.state.item_viewer,
                current: payload,
            }
        });
    }

    itemViewerClosed(e: FlzEvent) {
        this.setCurrentItem(null);

        if(!e.payload?.skipNavigation) {
            let nextUrl;
            const urlParts = this.router.getRouterState()[0]?.data;
            const params = this.router.getRouterState()[0]?.params;

            if (urlParts?.categorySlug == "items") {
                nextUrl = "/";
            } else {
                nextUrl = urlParts?.categorySlug;
            }

            if (params?.[RouteParams.category] === "items") {
                // remove both category & item
                delete params[RouteParams.category];
                delete params[RouteParams.item];
                nextUrl = "/";
            } else if (params?.[RouteParams.item]) {
                // remove only item
                delete params[RouteParams.item];
                nextUrl = "/";
            }

            if (nextUrl) {
                this.router.navTo(nextUrl, e.emitter.id);
            }
        }
    }

    changeCategory(e: FlzEvent) {
        this.router.navTo(e.payload.route, e.emitter.id);
        this.analyticsController.categoryChanged();
    }

    setCurrentCategory(payload: CategoryResponseV2, widgetId?: string) {
        this.store.setState({
            categories: {
                ...this.store.state.categories,
                current: {
                    category: payload,
                    widgetId: widgetId
                }
            },
            item_viewer: {
                ...this.store.state.item_viewer,
                current: null,
            }
        });
    }

    openLinkByTargetType(payload: {link: string, item: OpenItemViewerPayload, targetType?:string}) {
        if (payload.targetType === "anchor") {
            // set the hash in the url without navigating in vanilla js
            window.location.hash = payload.link;
            this.autoScrollToAnchor(payload.link);
            return;
        }
        const allowAppendParams = this.store.state.board.integrations.allow_append_params;
        let url = allowAppendParams ? appendParamsToUrl(payload.link) : payload.link;
        const targetType = payload.targetType || LINK_TARGETS.newTab;
        if (payload.item?.id) {
            this.analyticsController.trackItemView(
                payload.item.content_item_id,
                getAnalyticsSourceType(payload.item),
                getAnalyticsItemId(payload.item)
            );
        }
        url = removeQueryParams(url, "fw");
        window.open(withScheme(url), targetType);
    }

    getFileMetadata(e: FlzEvent) {
        this.sdk.liveboard.getFileMetadata(e.payload.contentItemId)
            .then(fileMetadata => onSuccessIfExists(e, fileMetadata))
            .catch(err => onErrorIfExists(e, err));
    }

    getFileDownloadUrl(e: FlzEvent) {
        this.sdk.liveboard.getContentDownloadUrl(e.payload.contentItemId)
            .then(downloadUrl => onSuccessIfExists(e, downloadUrl))
            .catch(err => onErrorIfExists(e, err));
    }

    stopTrackingForSession(e: FlzEvent | CustomEvent) {
        this.cookiesConsentService.stopTrackingForSession();

        this.sdk.liveboard.stopTrackingForSession()
            .then((lead) => {
                this.store.setState({lead});
                // @ts-ignore
                e.onSuccess && e.onSuccess();
            })
            // @ts-ignore
            .catch(err => e.onError && e.onError(err));
    }

    getFooters(e: FlzEvent) {
        const footers = window["FollozeState"].initialState.footers;
        onSuccessIfExists(e, footers);
    }

    getPrivacyMessages(e: FlzEvent) {
        const privacyMessages = window["FollozeState"].initialState.privacy_messages;
        onSuccessIfExists(e, privacyMessages);
    }

    getFormPrivacyMessage(e: FlzEvent) {
        this.sdk.liveboard.getFormPrivacyMessage(
            this.boardId,
            e.payload.privacyMessageId
        )
        .then(privacyMessage => onSuccessIfExists(e, privacyMessage))
        .catch(err => onErrorIfExists(e, err));
    }

    getOrgHeaderConfig(e: FlzEvent) {
        const orgHeaderConfig = window["FollozeState"].initialState.org_header_config;
        onSuccessIfExists(e, orgHeaderConfig);
    }

    getContactCardInfo(e: FlzEvent) {
        if (this.store.state.contact_card_info) {
            return onSuccessIfExists(e, this.store.state.contact_card_info);
        }
        this.sdk.liveboard.getSellerInformation(this.boardId)
            .then((contactCardInfo: BoardSellerResponseV1) => {
                this.store.setState({ contact_card_info: contactCardInfo });
                return onSuccessIfExists(e, contactCardInfo);
            })
            .catch(err => onErrorIfExists(e, err));
    }

    setLeadSessionCookie(e?: FlzEvent): Promise<number | void> {
        return this.sdk.liveboard.setSessionCookie(this.boardId)
            .then(() => e && onSuccessIfExists(e))
            .catch(err => e && onErrorIfExists(e, err));
    }

    setLeadCookiesConsent(e: FlzEvent, leadId: number) {
        return this.sdk.liveboard.setCookiesConsent(this.boardId, {
            leadId,
            constentOrigin: e.payload.consentOrigin,
            isoCode: get(this.cookiesConsentService.geoLocation, 'country_code', '')
        });
    }

    initializeCookieConsent() {
        const cookieConsentWidget = this.board.floatingWidgetElements.find(elem => (elem as CookieConsentWidget).isCookieConsentable);
        this.cookiesConsentService.initializeCookiesConsent(cookieConsentWidget as CookieConsentWidget);
    }

    getEnrichment() {
        console.debug("personalization: getting enrichment");
        return this.sdk.liveboard.getEnrichment(this.boardId);
    }

    addRegularPageLoader(percent: number, isTransparent: boolean = true) {
        // this is not the personalization page loader - do not be confused
        let pageLoader = this.board.shadowRoot?.querySelector("flz-page-loader");
        if (!pageLoader) {
            pageLoader = document.createElement("flz-page-loader");
            if (isTransparent) {
                pageLoader.setAttribute("transparent-bg", "true");
            }
            pageLoader.addEventListener("page-loader-done", (e) => {
                e.stopPropagation();
                pageLoader?.remove();
            });
            this.board.shadowRoot?.prepend(pageLoader);
        }

        // @ts-ignore
        pageLoader.loaded = percent;
    }

    refreshPersonalization(callback?: CallableFunction) {
        this.board.refreshPerso(callback);
    }

    getIsRegulatedCountry(e: FlzEvent) {
        const isRegulatedCountry = window["FollozeState"].initialState.is_regulated_country;
        onSuccessIfExists(e, isRegulatedCountry);
    }

    handleVisibilityChange(isHidden: boolean) {
        const event = new FlzBoardEvent(this.board, "visibility-change", {detail: {isHidden}});
        this.board.dispatchEvent(event);
    }

    private loadScriptIfDoesntExist(id: string, url: string) {
        const existingScript = this.board.querySelector(`script#${id}`);
        if (existingScript) {
            console.debug(`script ${id} already exists`);
            return;
        }
        console.debug(`loading script ${id}`);
        const script = document.createElement("script");
        script.id = id;
        script.src = url;
        this.board.appendChild(script);
    }

    loadAddToCalendarScript(_e: FlzEvent) {
        this.loadScriptIfDoesntExist("add-to-calendar-button", "https://cdn.jsdelivr.net/npm/add-to-calendar-button@2");
    }

    loadChatScript(_e: FlzEvent) {
        this.loadScriptIfDoesntExist("talkjs-chat", "https://cdn.talkjs.com/talk.js");
        // Loads a Talk object to the window, to support TalkJS's async loading.
        (function(t,a,l,k,j,s){
            // @ts-ignore
            k=t.Promise;t.Talk={v:3,ready:{then:function(f){if(k)return new k(function(r,e){l.push([f,r,e]);});l.push([f]);},catch:function(){return k&&new k();},c:l}};})(window,document,[]);
    }

    getPublicUrl(e:FlzEvent): void {
        const publicLink = getBoardPublicLink();

        onSuccessIfExists(e, publicLink);
    }

    createChatUser(e: FlzEvent): void {
        const userData = e.payload;
        this.sdk.liveboard.createChatUser(userData)
            .then((chatUser) => onSuccessIfExists(e, chatUser))
            .catch((err) => onErrorIfExists(e, err));
    }

    joinChatConversation(e: FlzEvent): void {
        const {conversationId, userId} = e.payload;
        this.sdk.liveboard.joinChatConversation(conversationId, userId)
            .then((res) => onSuccessIfExists(e, res))
            .catch((err) => onErrorIfExists(e, err));
    }
    
    leaveChatConversation(e: FlzEvent): void {
        const {conversationId, userId} = e.payload;
        this.sdk.liveboard.leaveChatConversation(conversationId, userId)
            .then((res) => onSuccessIfExists(e, res))
            .catch((err) => onErrorIfExists(e, err));
    }

    getLiveEventParticipants(e: FlzEvent): void {
        const {liveEventId} = e.payload;
        this.sdk.liveboard.getLiveEventParticipants(this.boardId, liveEventId)
        .then((res) => onSuccessIfExists(e, res))
        .catch((err) => onErrorIfExists(e, err));
    }

    joinLiveEvent(e: FlzEvent): void {
        const {liveEventId} = e.payload;
        this.sdk.liveboard.joinLiveEvent(this.boardId, liveEventId)
            .then((res) => onSuccessIfExists(e, res))
            .catch((err) => onErrorIfExists(e, err));
    }

    leaveLiveEvent(e: FlzEvent): void {
        const {liveEventId} = e.payload;
        this.sdk.liveboard.leaveLiveEvent(this.boardId, liveEventId)
            .then((res) => onSuccessIfExists(e, res))
            .catch((err) => onErrorIfExists(e, err));
    }

    formCloseRequest(e: FlzEvent) {
        this.closeModal();
    }
}
