import VisibilityService from "./VisibilityService";
import IdleService from "./IdleService";
import {AnalyticsController} from "../controllers/AnalyticsController";

const HIDDEN_TIME_FOR_IDLE = 1000 * 40; // 40 seconds

export default class SessionService {
    private isIdle: boolean = false;
    private isHidden: boolean = false;
    private hiddenAt: number | null = null;
    private visibilityService: VisibilityService;
    private idleService: IdleService;
    private controller: AnalyticsController;

    constructor(controller: AnalyticsController, visibilityService: VisibilityService) {
        this.controller = controller;
        this.idleService = new IdleService();
        this.visibilityService = visibilityService;
        this.initialize();
    }

    initialize() {
        this.idleService.onChange((isIdle: boolean) => this.handleIdleChanged(isIdle));
        this.visibilityService.onChange((isHidden: boolean) => this.handleHiddenChanged(isHidden));
    }

    handleIdleChanged(isIdle: boolean) {
        this.isIdle = isIdle;
        const isActive = this.isSessionActive();
        this.handleUpdateSessionStatus(isActive, isActive);
    }

    handleHiddenChanged(isHidden: boolean) {
        if (isHidden !== this.isHidden) {
            this.controller.setTabIsHidden(isHidden);
        }
        this.isHidden = isHidden;
        let shouldCreateSession = false;
        const isActive = this.isSessionActive();

        if (this.isHidden) {
            this.hiddenAt = Date.now();
        } else {
            shouldCreateSession = isActive && this.isTabHiddenIdle();
            this.hiddenAt = null;
        }
        this.handleUpdateSessionStatus(isActive, shouldCreateSession);
    }

    isSessionActive() {
        return !this.isIdle && !this.isHidden;
    }

    isTabHiddenIdle() {
        if (this.isHidden === null) {
            return false;
        }
        return ( Date.now() - this.hiddenAt! ) >= HIDDEN_TIME_FOR_IDLE;
    }

    handleUpdateSessionStatus = (isActive: boolean, shouldCreateSession: boolean) => {
        if (shouldCreateSession) {
            //in case user returns to tab after the defined idle time or after mouse move
            this.controller.createSession();
        }
        else {
            this.controller.setSessionState(isActive);
        }
    };

    getLastIdleCheck() {
        return this.idleService.lastMouseMove;
    }
}

